<template>
    <div class="container" id="container">
        <div class="titlebox">
            <div class="main">
                <div class="name">
                    浙江数翰科技有限公司
                    <h1 v-show="false" @click="gotomore()">浙江数翰科技有限公司</h1>

                </div>
                <div class="text">
                    <div>
                        专注于为环保行业提供人工智能解决方案。
                    </div>
                    <div>
                        以减污降碳为目标，致力于打造数智化低碳工厂，助力环保产业的绿色转型与高质量发展。
                    </div>

                </div>
            </div>
        </div>
        <div class="since">
            <div class="main">
                <div class="title" :class="showsince ? 'joinleft' : ''">
                    智慧水务的践行者
                </div>
                <div class="something" :class="showsince ? 'joinleft1' : ''">
                    核心算法+专业软件+可视化平台+行业物联网+场景定制化服务
                </div>
                <div class="text" :class="showsince ? 'joinleft2' : ''">
                    数翰科技以算法为技术核心，借助机器学习、仿真模拟、大数据云计算、物联网（IoT）、移动边缘计算（MEC）、进化算法等前沿技术，将AI技术与环保应用相结合，深度挖掘数据价值，通过算法研发和场景创新、为客户提供自动化、信息化、数智化的工业全场景节能降耗解决方案。
                </div>
                <div class="since_img">
                    <img src="../../assets/SINCE@2x.png" alt="" class="img1">
                    <img src="../../assets/2019@2x.png" alt="" class="img2">
                </div>
            </div>
        </div>
        <div class="everytime">
            <div class="main" v-show="showevery">
                <div class="every_left" :class="showevery ? 'joinleft' : ''">
                    <div class="text">
                        <p>
                            无论何时何地，
                        </p>
                        数翰团队都能为您提供全面的服务。
                    </div>

                    <div class="text2">深研底层算法，赋能工业发展</div>
                </div>
                <div class="every_right">
                    <div class="everyone">
                        <div class="everyone_box" v-for="(item, index) in everylist"
                            :class="showevery ? 'goup' + index : ''">
                            <!-- <scrollnum :value="item.runbt"></scrollnum> -->
                            <div class="num">{{ item.runbt }}</div>
                            <div class="text">{{ item.name }}</div>
                        </div>
                    </div>
                    <div class="detail" :class="showevery ? 'goup' : ''">
                        数翰团队内拥有多名跨学科人才，全面了解污水处理行业的专业知识的同时，结合工业控制深入研究数翰AI控制算法，打造AI运维决策控制系统，为污水厂的稳定性、经济性提供保障。
                    </div>
                </div>
            </div>
        </div>
        <div class="plan">
            <div class="main" v-show="showplan">
                <div class="plan_title">
                    <p :class="showevery ? 'goup' : ''">
                        专注于为环保行业提供
                    </p>
                    <p class="manandai" :class="showevery ? 'goup1' : ''">
                        人工智能解决方案
                    </p>
                </div>
                <div class="plan_littletitle" :class="showevery ? 'goup2' : ''">
                    "AI赋能+数字化建设+产品运营"，为工业持续赋能，降低生产成本
                </div>
                <div class="plan_imgbox">
                    <div class="img_left" :class="showevery ? 'goup1' : ''">
                        <div class="imgbox">
                            <img src="../../assets/downc.png" alt="">
                        </div>
                        以减污降碳为目标
                    </div>
                    <div class="img_right" :class="showevery ? 'goup1' : ''">

                        <div class="imgbox">
                            <img src="../../assets/gongchang.png" alt="">
                        </div>
                        打造数智化低碳工厂
                        <div class="imgbox">
                            <img src="../../assets/aitechnology.png" alt="">
                        </div>
                        助力环保产业的绿色转型与高质量发展
                    </div>
                </div>
            </div>
        </div>
        <div class="honour">
            <div class="main">
                <div class="honour_title">
                    荣誉与资质
                </div>
                <div class="tab_box">

                    <div class="module_tab">
                        <div class="tab_text" v-for=" i  in tablist" @click="tabclick(i)">
                            <span class="blue_line" v-show="tabindex == i.index"></span>
                            <div class="tab_name" :class="tabindex == i.index ? 'clickstyle' : ''">
                                {{ i.name }}
                            </div>
                        </div>
                    </div>
                    <div class="module_swiper" id="swiper-top" v-if="tabindex == 0">
                        <div class="theSwiper" ref="swiper1">
                            <swiper :options="swiperOption">
                                <swiper-slide v-for="item in modulelist[0]" :key="item.index">
                                    <div class="imgbox">
                                        <img :src="item.url" alt="">
                                    </div>
                                    <div>
                                        {{ item.name }}
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <div class="swiper-navigation" slot="navigation"></div>
                            <div slot="button-prev" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-prev'
                                "></div>
                            <div slot="button-next" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-next'
                                "></div>
                        </div>
                    </div>
                    <div class="module_swiper" id="swiper-top1" v-if="tabindex == 1">
                        <div class="theSwiper" ref="swiper2">
                            <swiper :options="swiperOption1">
                                <swiper-slide v-for="item in modulelist[1]" :key="item.index">
                                    <div class="imgbox">
                                        <img :src="item.url" alt="">
                                    </div>
                                    <div>
                                        {{ item.name }}
                                    </div>
                                </swiper-slide>
                            </swiper>
                            <div class="swiper-navigation" slot="navigation"></div>
                            <div slot="button-prev" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-prev'
                                "></div>
                            <div slot="button-next" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-next'
                                "></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="callme">
            <div class="main">
                <div class="callme_title">
                    在线留言
                </div>
                <div class="callme_box">
                    <div class="map_box">
                        <MapContainer></MapContainer>
                    </div>
                    <div class="info_box">
                        <div class="top">

                            <div class="itembox">
                                姓名
                                <input type="text" name="" id="" v-model="name">
                            </div>
                            <div class="itembox">
                                邮箱
                                <input type="text" name="" id="" v-model="email">
                            </div>
                        </div>
                        <div class="content">

                            <div class="itembox">
                                电话
                                <input type="text" name="" id="" v-model="phone">
                            </div>

                        </div>
                        <div class="bottom">

                            <div class="itembox1">
                                <p>
                                    内容
                                </p>
                                <textarea cols="50" rows="10" v-model="message"></textarea>
                            </div>
                        </div>
                        <div class="commit">
                            <el-button type="primary" class="buttonClass" @click="pushsome()">提交</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slogan">
            <div class="text">
                为时代所依赖
                <div class="line"></div>
            </div>
        </div>
        <div class="footer">
            <myfoooter></myfoooter>
        </div>

    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import MapContainer from "../../components/map.vue";
import scrollnum from "../../components/scrollnum.vue";

import myfoooter from "../../components/footer.vue";
export default {
    components: {
        myfoooter,
        swiper,
        swiperSlide,
        MapContainer,
        scrollnum
    },
    data() {
        return {
            ruleForm: {
                pass: '',
                checkPass: '',
                age: ''
            },
            tablist: [
                {
                    index: 0,
                    name: '企业资信'
                },
                {
                    index: 1,
                    name: '知识产权'
                }
            ],
            tabindex: 0,
            everylist: [
                {
                    index: 0,
                    runbt: '40+',
                    name: '跨学科人才',
                }, {
                    index: 1,
                    runbt: '6%',
                    name: '研发投入',
                }, {
                    index: 2,
                    runbt: '20+',
                    name: '专利技术',
                },
            ],
            modulelist: [
                [

                    {
                        index: 0,
                        runbt: false,
                        name: 'ISO9001质量管理体系认证证书',
                        url: require("../../assets/Creditworthiness/数翰科技三体系认证证书（质量、环境、职业健康）.png"),

                    },
                    {
                        index: 1,
                        runbt: false,
                        url: require("../../assets/Creditworthiness/污水管理数据分析系统--数翰科技.png"),
                        name: '软件产品证书',
                    },

                    {
                        index: 2,
                        runbt: false,
                        url: require("../../assets/Creditworthiness/数翰项目管理系统软件产品--数翰科技.png"),
                        name: '软件产品证书',
                    },
                    {
                        index: 3,
                        url: require("../../assets/Creditworthiness/科技省科技型中小公司证书.png"),
                        runbt: false,
                        name: '浙江省科技型中小企业证书',
                    },

                    {
                        url: require("../../assets/Creditworthiness/浙江数翰科技软件企业证书.png"),
                        index: 4,
                        runbt: false,
                        name: '软件企业证书',
                    },
                    {
                        url: require('../../assets/Creditworthiness/b91e556ce2ebe7b48a0fdfbbf08cbe1.png'),
                        index: 5,
                        name: 'ISO14001环境管理体系认证证书'
                    }, {
                        url: require('../../assets/Creditworthiness/bce1ca1bda85208dc31727c8cf84f0b.png'),
                        index: 6,
                        name: 'ISO45001职业健康安全管理体系认证证书'
                    }
                ], [
                    {
                        index: 0,
                        runbt: false,
                        name: '发明专利',
                        url: require("../../assets/intellectuals/1.基于污水检测的光谱解析方法和系统专利证书.png"),
                    },
                    {
                        index: 1,
                        runbt: false,
                        url: require("../../assets/intellectuals/10.2021SR04475546软著证书变更证明-污水管理数据分析系统.png"),
                        name: '数据管理分析软著证书',

                    },
                    {
                        index: 2,
                        runbt: false,
                        url: require("../../assets/intellectuals/11.AI运维决策控制系统视频（3D鸟瞰视频）证书.png"),
                        name: 'AI运维决策系统作品登记证书',
                    },
                    {
                        index: 3,
                        url: require("../../assets/intellectuals/6.2019SR1100920软件著作变更证明-数翰污水处理信息管理系统.png"),
                        runbt: false,
                        name: '污水处理信息软著证书',
                    },

                    {
                        url: require("../../assets/intellectuals/8.2020SR0331450软件著作变更证明-数翰数据汇聚小程序软件.png"),
                        index: 4,
                        runbt: false,
                        name: '数据汇聚小程序软著证书',
                    },
                    {
                        url: require("../../assets/intellectuals/7.2019SR1100917软件著作变更证明-数翰项目管理系统.png"),
                        index: 5,
                        runbt: false,
                        name: '项目管理系统软著证书',
                    },
                    {
                        url: require("../../assets/intellectuals/9.2020SR0332459软件著作变更证明-数翰数据汇聚系统.png"),
                        index: 6,
                        runbt: false,
                        name: '数据汇聚系统软著证书',
                    },
                ]
            ],
            eqlength: 5,
            swiperOption: {
                // loop: true, //首尾循环
                // autoplay: {
                //   delay: 600,
                //   stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
                //   disableOnInteraction: false, //true：交互后，不继续滚动
                // },
                initialSlide: 1,
                slidesPerView: 4, //页面中的板块数量
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                freeMode: false, //true:拖到哪就是哪
                spaceBetween: 40, //板块间隔
                grabCursor: false, //true：鼠标为抓手
                centeredSlides: true,
                // slidesOffsetBefore: 80,
                // touchStartPreventDefault: false,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //true：点击底部小圆点可以跳转页面
                    type: "fraction", //当前页显示 1/7
                },
                prevButton: "#swiper-top .swiper-button-prev",
                nextButton: "#swiper-top .swiper-button-next",
                // 设置点击箭头
                navigation: {
                    nextEl: "#swiper-top .swiper-button-next",
                    prevEl: "#swiper-top .swiper-button-prev",
                },
            },
            swiperOption1: {
                // loop: true, //首尾循环
                // autoplay: {
                //   delay: 600,
                //   stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
                //   disableOnInteraction: false, //true：交互后，不继续滚动
                // },
                initialSlide: 1,
                centeredSlides: true,
                slidesPerView: 4, //页面中的板块数量
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                freeMode: false, //true:拖到哪就是哪
                spaceBetween: 40, //板块间隔
                grabCursor: false, //true：鼠标为抓手
                centeredSlides: true,
                // slidesOffsetBefore: 80,
                // touchStartPreventDefault: false,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //true：点击底部小圆点可以跳转页面
                    type: "fraction", //当前页显示 1/7
                },
                prevButton: "#swiper-top1 .swiper-button-prev",
                nextButton: "#swiper-top1 .swiper-button-next",
                // 设置点击箭头
                navigation: {
                    nextEl: "#swiper-top1 .swiper-button-next",
                    prevEl: "#swiper-top1 .swiper-button-prev",
                },
            },
            name: '',
            phone: '',
            email: '',
            message: '',
            showsince: false,
            showevery: false,
            showplan: false
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.showsince = true
    },
    mounted() {
        this.initSwiper()
        this.$nextTick(() => {

            var parent = document.getElementById('container')
            console.log(window.scrollY);
            parent.scrollTop = 0
        })
        // navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);
    },


    methods: {
        handleScroll() {
            let scrollall = document.documentElement.clientHeight || document.body.offsetHeight

            let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop);
            if (scrollTop > scrollall * 0.29) {
                this.showevery = true
            }
            if (scrollTop > scrollall * 0.99) {
                this.showplan = true
            }
            // if (scrollTop > 1900) {
            //     this.showTNchart = true
            // }
        },
        tabclick(i) {
            this.tabindex = i.index
            // this.initSwiper()

        },


        successCallback(position) {
            const latitude = position.coords.latitude; // 纬度
            const longitude = position.coords.longitude; // 经度
            console.log("经度：" + longitude);
            console.log("纬度：" + latitude);
        },

        errorCallback(error) {
            alert("获取位置信息失败：" + error.message);
        },

        initSwiper() {
            this.swiperOption = {
                // loop: true, //首尾循环
                // autoplay: {
                //   delay: 600,
                //   stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
                //   disableOnInteraction: false, //true：交互后，不继续滚动
                // },
                slidesPerView: 5, //页面中的板块数量
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                freeMode: false, //true:拖到哪就是哪
                spaceBetween: 30, //板块间隔
                grabCursor: false, //true：鼠标为抓手
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //true：点击底部小圆点可以跳转页面
                    type: "fraction", //当前页显示 1/7
                },
                // 设置点击箭头
                navigation: {
                    nextEl: "#swiper-top .swiper-button-next",
                    prevEl: "#swiper-top .swiper-button-prev",
                },
            };
        },
        async pushsome() {
            // console.log(this.name, this.phone, this.email, this.message);
            // return
            const { data: res } = await this.$http.post("/send_emails", {
                name: this.name,
                phone: this.phone,
                email: this.email,
                message: this.message
            });
            console.log(res);
            if (res.message == "message sent successfully!") {
                this.$message({
                    message: '我们已收到您的留言，近期会给您回复',
                    type: 'success'
                });
            }
        }
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

@keyframes titleShow {
    from {
        transform: translateY(1000%);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes leftShow {
    from {
        transform: translatex(-100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes rightShow {
    from {
        transform: translatex(100%);
    }

    to {
        transform: translateX(0px);
    }
}

.goup {
    animation: titleShow 1s;

}

.goup0 {
    animation: titleShow 1s;

}

.goup1 {
    animation: titleShow 1.5s;

}

.goup2 {
    animation: titleShow 2s;

}

.joinleft {
    animation: leftShow 1s;
}

.joinleft1 {
    animation: leftShow 1.5s;
}

.joinleft2 {
    animation: leftShow 2s;
}

.joinright {
    animation: rightShow 1s;
}

.container {
    display: grid;
    overflow: auto;
    height: 100%;
    margin: 0 auto;
    overflow: visible;


    .titlebox {
        .main {
            width: 1522px;
            margin: 0 auto;
        }

        height: 793px;
        background-image: url("../../assets/0025c.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;
        // background: linear-gradient(90deg, rgba(35, 74, 117, 0.9) 0%, rgba(255, 255, 255, 0) 100%);

        .name {
            margin-top: 363px;
            font-size: 36px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1)
        }

        .text {
            margin-top: 66px;
            width: 667px;
            height: 116px;
            font-size: 20px;
            font-weight: 400;
            line-height: 28.96px;
            color: rgba(255, 255, 255, 1);
        }
    }

    .since {
        overflow: hidden;
        height: 604px;
        background-image: url("../../assets/sincebg.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(255, 255, 255);

        .main {
            width: 1422px;
            margin: 0 auto;
        }

        .title {
            margin-top: 147px;
            width: 260px;
            height: 38px;
            font-size: 32px;
            font-weight: 400;
            line-height: 37.5px;
            color: rgba(105, 105, 105, 1);
        }

        .something {
            margin-top: 10px;
            width: 655px;
            height: 29px;
            font-size: 22px;
            font-weight: 400;
            line-height: 28.13px;
            color: rgba(100, 149, 237, 1);
        }

        .text {
            margin-top: 78px;
            width: 916px;
            height: 140px;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: rgba(110, 110, 110, 1);
        }

        .since_img {
            margin-top: 16px;
            // position: absolute;
            text-align: right;
            // right: 100;

            .img1 {
                width: 335px;
                margin-right: 68px;
                margin-top: 15px;
            }

            .img2 {
                // position: absolute;
                // top: 0;
                width: 283px;
                margin-bottom: 12px;
            }
        }
    }

    .everytime {
        overflow: hidden;
        height: 660px;
        background-image: url("../../assets/everytime.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1422px;
            margin: 0 auto;
            display: flex;
        }

        .every_left {
            margin-right: 370px;

            .text {
                p {
                    margin: 0 !important;
                }

                margin-top: 181px;
                width: 400px;
                height: 174px;
                font-size: 40px;
                font-weight: 500;
                line-height: 57.92px;
                color: rgba(217, 217, 217, 1);
            }

            .text2 {
                margin-top: 86px;
                width: 364px;
                height: 41px;
                font-size: 28px;
                font-weight: 400;
                line-height: 40.54px;
                color: rgba(217, 217, 217, 1);
            }
        }

        .every_right {
            margin-top: 181px;

            .everyone {
                display: flex;
                text-align: center;

                .everyone_box {
                    flex: 1;

                    .num {

                        font-size: 36px;
                        font-weight: 900;
                        line-height: 47.48px;
                        // width: 110px;
                        // text-align: center;
                        color: rgba(100, 149, 237, 1);
                    }

                    .text {
                        // width: 110px;
                        font-size: 22px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 30.8px;
                        color: rgba(222, 222, 222, 1);
                    }
                }

                .everyone_box:last-child {
                    text-align: end;

                    .num {
                        width: 220px;
                    }
                }

                .everyone_box:first-child {
                    text-align: start;

                    .num {
                        width: 110px;
                        text-align: center;

                    }

                }
            }

            .detail {
                margin-top: 128px;
                width: 676px;
                height: 82px;
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                color: rgba(148, 148, 148, 1);
            }
        }
    }

    .plan {
        overflow: hidden;
        height: 1455px;
        background: linear-gradient(270deg, rgba(108, 110, 121, 1) 0%, rgba(50, 52, 73, 1) 61.11%);

        .main {
            width: 1422px;
            margin: 0 auto;

            .plan_title {

                p {
                    margin: 0 !important;
                    font-size: 36px;
                    font-weight: 400;
                    letter-spacing: 0.1px;
                    line-height: 70px;
                    color: rgba(217, 217, 217, 1);
                }

                .manandai {

                    font-size: 60px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    color: rgba(255, 255, 255, 1);
                }

                margin-top: 185px;
            }

            .plan_littletitle {
                margin-top: 40px;
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 33.6px;
                color: rgba(217, 217, 217, 1);

            }

            .plan_imgbox {
                margin-top: 70px;
                display: flex;

                .img_left {
                    flex: 1;
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 34.48px;
                    color: rgba(255, 255, 255, 1);

                    .imgbox {
                        margin-top: 104px;
                        margin-bottom: 50px;
                        left: 263px;
                        top: 2628px;
                        width: 503px;
                        height: 666px;
                        opacity: 1;
                        border-radius: 10px;

                        img {
                            width: 100%;
                        }

                    }
                }

                .img_right {
                    flex: 1;
                    margin-left: 297px;
                    font-size: 26px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 34.48px;
                    color: rgba(255, 255, 255, 1);


                    .imgbox {

                        width: 531px;
                        height: 307.75px;
                        opacity: 1;
                        border-radius: 10px;
                        margin-bottom: 50px;

                        img {
                            width: 100%;
                        }
                    }

                    .imgbox:last-child {
                        margin-top: 70px;
                    }
                }
            }
        }
    }

    .honour {
        height: 718px;
        background-image: url("../../assets/honour.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(255, 255, 255);

        .main {
            width: 1422px;
            margin: 0 auto;
        }

        .honour_title {
            margin-top: 124px;
            font-size: 36px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 42.19px;
            color: rgba(105, 105, 105, 1);
        }

        .tab_box {
            margin-top: 80px;
            display: flex;

            .tab_text {
                display: flex;
            }

            .tab_name {
                margin-left: 18px;
                margin-bottom: 40px;
                vertical-align: top;
                height: 29px;
                line-height: 29px;
                font-size: 24px;
                font-weight: 400;
                color: rgba(181, 181, 181, 1);
            }

            .clickstyle {
                color: rgba(105, 105, 105, 1);

            }

            .blue_line {
                display: inline-block;
                width: 6px;
                height: 29px;
                opacity: 1;
                background: rgba(100, 149, 237, 1);

            }

            .module_swiper {
                width: 1200px;
                // margin-top: 226px;
                margin: auto;
                // margin-left: 100px;
                height: 484px;
                position: relative;

            }

            .swiper-button-prev:after {
                display: none;
            }

            .swiper-button-next:after {
                display: none;
            }

            /*再自定义样式*/
            .swiper-button-prev {
                box-shadow: 1PX 1PX 8PX 2PX rgba(0, 0, 0, 0.05);
                border-radius: 50%;
                width: 35px;
                height: 35px;
                background: url('../../assets/678647682a948a36785acbce39fefae1.png') no-repeat;
                // background: url('../../assets/after2x.png') no-repeat;
                bottom: 15px;
                background-size: 100% 100%;
                background-position: center center;
                top: 60%;
                left: 35%;
            }

            .swiper-button-prev:hover {
                width: 35px;
                height: 35px;
                background: url('../../assets/5286baf52f2e804163781680e988600.png') no-repeat;
                box-shadow: 0PX 0PX 0PX 0PX rgba(0, 0, 0, 0.05);
                bottom: 15px;
                background-size: 100% 100%;
                background-position: center center;
                top: 60%;
                left: 35%;
            }

            .swiper-button-next {
                box-shadow: 1PX 1PX 8PX 2PX rgba(0, 0, 0, 0.05);
                border-radius: 50%;

                width: 35px;
                height: 35px;
                background: url('../../assets/678647682a948a36785acbce39fefae.png') no-repeat;
                top: 60%;
                left: 62%;
                background-size: 100% 100%;
                background-position: center center;
            }

            .swiper-button-next:hover {
                width: 35px;
                height: 35px;
                box-shadow: 0PX 0PX 0PX 0PX rgba(0, 0, 0, 0.05);

                background: url('../../assets/5286baf52f2e804163781680e988601.png') no-repeat;

                top: 60%;
                left: 62%;
                background-size: 100% 100%;
                background-position: center center;
            }

            .theSwiper {
                width: 100%;
                margin-bottom: 30px;
                overflow: hidden;

                .swiper-container {
                    position: relative;
                    width: 100%;
                    // height: 400px;



                    .swiper-slide {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 260px;
                        // margin: 0 !important;
                        color: rgb(95, 28, 28);
                        // background-color: rgba(30, 103, 212, 0.116);
                        font-size: 16px;
                        text-align: center;
                    }

                    .swiper-slide-active {

                        .imgbox {
                            height: 206px;
                            width: 283px;
                            // width: 120%;

                            img {
                                height: 100%;
                                // width: 100%;
                            }
                        }
                    }

                    .imgbox {
                        height: 150px;

                        img {
                            height: 100%;
                            // width: auto;
                        }
                    }
                }
            }

        }

    }

    .callme {
        height: 920px;
        background: linear-gradient(270deg, rgba(108, 110, 121, 1) 0%, rgba(50, 52, 73, 1) 61.11%);

        .main {
            width: 1422px;
            margin: 0 auto;

            .callme_title {
                margin-top: 130px;
                font-size: 36px;
                font-weight: 400;
                line-height: 42.19px;
                color: rgba(252, 252, 252, 1);
            }

            .callme_box {
                margin-top: 86px;
                display: flex;

                .map_box {
                    background-color: #fff;
                    width: 652px;
                    height: 525px;
                    opacity: 1;
                    border-radius: 10px;
                }

                .info_box {
                    margin-left: 196px;

                    .top {
                        display: flex;
                    }

                    .content {
                        margin-top: 60px;
                    }

                    .itembox {
                        margin-right: 98px;
                        height: 52px;
                        width: 228px;
                        font-size: 20px;
                        font-weight: 400;
                        line-height: 35px;
                        color: rgba(227, 227, 227, 1);
                        text-align: left;
                        vertical-align: top;
                        border-bottom: 0.6px solid rgba(209, 209, 209, 1);

                        input {
                            border: 0; // 去除未选中状态边框
                            outline: none; // 去除选中状态边框
                            background-color: rgba(0, 0, 0, 0) !important; // 透明背景
                            height: 35px;
                            width: 160px;
                            color: rgba(227, 227, 227, 1);

                            font-size: 20px;
                        }
                    }

                    .bottom {
                        margin-top: 70px;

                        .itembox1 {
                            margin-right: 98px;
                            height: 150px;
                            width: 554px;
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 35px;
                            color: rgba(227, 227, 227, 1);
                            text-align: left;
                            vertical-align: top;
                            border-bottom: 0.6px solid rgba(209, 209, 209, 1);

                            textarea {

                                border: 0; // 去除未选中状态边框
                                outline: none; // 去除选中状态边框
                                background-color: rgba(0, 0, 0, 0) !important; // 透明背景
                                height: 84px;
                                width: 100%;
                                font-size: 20px;
                                color: rgba(227, 227, 227, 1);

                            }

                            input {}
                        }

                    }

                    .commit {
                        margin-top: 69px;

                        .buttonClass {
                            width: 154px;
                            height: 50px;
                            border-radius: 0;
                            font-size: 20px;
                            background: rgba(100, 149, 237, 1);
                        }

                    }

                }
            }
        }

    }

    .slogan {
        height: 342px;
        background-image: url("../../assets/slogan.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: rgb(255, 255, 255);
        text-align: center;

        .text {
            margin-top: 218px;
            font-size: 36px;
            font-weight: 400;
            letter-spacing: 10px;
            line-height: 42.19px;
            color: rgba(255, 255, 255, 1);

            .line {
                margin: auto;
                margin-top: 15px;
                width: 185px;
                height: 4px;
                opacity: 1;
                background: #6495ED;
            }
        }
    }

    .footer {
        position: relative;
        height: 300px;
        color: #C4C4C4;
        background-color: #20262d;
    }
}
</style>
