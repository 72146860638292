<template>
    <div class="container">
        <div class="service_box">
            <div class="main">
                <div class="service_title">
                    <p>
                        Service & After-sales
                    </p>
                    <p>
                        服务与售后
                    <h1 v-show="false" @click="gotomore()">浙江数翰科技有限公司</h1>

                    </p>
                </div>
            </div>
        </div>
        <div class="we_service">
            <div class="main">
                <div class="we_service_title" :class="showtitle ? 'goup' : ''">
                    <p>
                        我们旨在为客户提供
                    </p>
                    <p>多位一体的全面服务</p>
                </div>
                <div class="we_service_center">
                    <div class="algorithm" :class="showtitle ? 'goup' : ''">核心算法</div>
                    <div class="software" :class="showtitle ? 'goup' : ''">专业软件</div>
                    <div class="customize" :class="showtitle ? 'fade' : ''">场景定制化服务</div>
                    <div class="visualization" :class="showtitle ? 'goup' : ''">可视化平台</div>
                    <div class="things" :class="showtitle ? 'goup' : ''">行业物联网</div>
                </div>
                <div class="jiantoubox">
                    <img src="../../assets/jiantou (2).png" alt="">
                </div>
            </div>
        </div>
        <div class="customization_service">
            <div class="main">
                <div class="title" :class="showcustitle ? 'goup' : ''">
                    <p>
                        场景定制化服务
                    </p>
                    <p>针对不同水厂的独特性，我们提供一对一场景定制化服务</p>
                </div>
                <div class="content">
                    <div class="analyse border" @mouseenter="hoverhear('analyse')" @mouseleave="leavehear('analyse')"
                        :class="analyse ? 'hoverthis' : ''">
                        <div class="contentbox">
                            <div class="content_title">
                                场景分析
                            </div>
                            <div class="content_some">
                                深入现场，获取客户需求进行场景分析
                            </div>
                        </div>
                    </div>
                    <div class="collect border" @mouseenter="hoverhear('collect')" @mouseleave="leavehear('collect')"
                        :class="collect ? 'hoverthis' : ''">
                        <div class="contentbox">
                            <div class="content_title">
                                信息收集
                            </div>
                            <div class="content_some">
                                利用水厂信息，构建数据图谱
                            </div>
                        </div>
                    </div>
                    <div class="excavate border" @mouseenter="hoverhear('excavate')" @mouseleave="leavehear('excavate')"
                        :class="excavate ? 'hoverthis' : ''">
                        <div class="contentbox">
                            <div class="content_title">
                                价值挖掘
                            </div>
                            <div class="content_some">
                                智能化控制，挖掘AI调控价值
                            </div>
                        </div>
                    </div>
                    <div class="mastermind border" @mouseenter="hoverhear('mastermind')"
                        @mouseleave="leavehear('mastermind')" :class="mastermind ? 'hoverthis' : ''">
                        <div class="contentbox">
                            <div class="content_title">
                                方案策划
                            </div>
                            <div class="content_some">
                                定制化方案设计，统筹全厂控制
                            </div>
                        </div>
                    </div>
                    <div class="customize border" @mouseenter="hoverhear('customize')" @mouseleave="leavehear('customize')"
                        :class="customize ? 'hoverthis' : ''">
                        <div class="contentbox">
                            <div class="content_title">
                                产品定制
                            </div>
                            <div class="content_some">
                                适配水厂的AI大模型+专业软件，AI精准赋能
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="after_sales">
            <div class="main" v-show="showafter">
                <div class="after_title">
                    我们的售后
                </div>
                <div class="after_content">
                    <div class="top">
                        <div class="content_box left" :class="showafter ? 'joinleft' : ''">
                            <div class="num">
                                01
                            </div>
                            <div class="some">
                                <div class="some_title">
                                    快速响应
                                </div>
                                <div class="some_content">
                                    24小时全天候在线，实时跟进现场运行情况，快速响应现场需求
                                </div>
                                <div class="some_content">
                                    遇险情时，AI系统响应迅速，自查、自报、自恢复，告警强提醒
                                </div>
                            </div>
                            <div class="img">
                                <img src="../../assets/矩形 7@2x.png" alt="">
                            </div>
                        </div>
                        <div class="line"></div>
                        <div class="content_box" :class="showafter ? 'joinright' : ''">
                            <div class="num">
                                02
                            </div>
                            <div class="some">
                                <div class="some_title">
                                    定期维保
                                </div>
                                <div class="some_content">
                                    AI系统及服务器硬件的定期维护保养服务
                                </div>

                            </div>
                            <div class="img">
                                <img src="../../assets/矩形 7@2x (1).png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="content_box left" :class="showafter ? 'joinleft1' : ''">
                            <div class="num">
                                03
                            </div>
                            <div class="some">
                                <div class="some_title">
                                    数据分析
                                </div>
                                <div class="some_content">
                                    <p>

                                        建立数据图谱
                                    </p>
                                    定期提供工艺优化诊断方案
                                </div>
                            </div>
                            <div class="img">
                                <img src="../../assets/矩形 7@2x (2).png" alt="">
                            </div>
                        </div>
                        <div class="line"></div>

                        <div class="content_box" :class="showafter ? 'joinright1' : ''">
                            <div class="num">
                                04
                            </div>
                            <div class="some">
                                <div class="some_title">
                                    迭代升级
                                </div>
                                <div class="some_content">
                                    <p>

                                        服务期内
                                    </p>
                                    享受数翰科技算法大模型的迭代升级
                                </div>
                            </div>
                            <div class="img">
                                <img src="../../assets/矩形 7@2x (3).png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <myfoooter></myfoooter>
        </div>

    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import myfoooter from "../../components/footer.vue";
import scrollnum from "../../components/scrollnum.vue";
import "swiper/dist/css/swiper.css";
export default {
    components: {
        myfoooter,
        swiper,
        swiperSlide,
        scrollnum
    },
    data() {
        return {
            analyse: false,
            collect: false,
            excavate: false,
            mastermind: false,
            customize: false,
            showtitle: false,
            showcustitle: false,
            showafter: false
        };
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
        this.showtitle = true
    },
    mounted() {

    },

    methods: {
        handleScroll() {
            let scrollall = document.documentElement.clientHeight || document.body.offsetHeight
            let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop);
            if (scrollTop > scrollall * 0.44) {
                this.showcustitle = true
            }
            if (scrollTop > scrollall * 0.93) {
                this.showafter = true
            }
            // if (scrollTop > 1900) {
            //     this.showTNchart = true
            // }
        },
        hoverhear(some) {
            console.log(some);
            if (some == 'analyse') {
                this.analyse = true
            }
            if (some == 'collect') {
                this.collect = true
            }
            if (some == 'excavate') {
                this.excavate = true
            }
            if (some == 'mastermind') {
                this.mastermind = true
            }
            if (some == 'customize') {
                this.customize = true
            }
            // this.some
        },
        leavehear(some) {
            console.log(some);
            if (some == 'analyse') {
                this.analyse = false
            }
            if (some == 'collect') {
                this.collect = false
            }
            if (some == 'excavate') {
                this.excavate = false
            }
            if (some == 'mastermind') {
                this.mastermind = false
            }
            if (some == 'customize') {
                this.customize = false
            }
        },
        gotomore() {
            this.$router.push('/aboutus');
        }
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

@keyframes titleShow {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes leftShow {
    from {
        transform: translatex(-100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes rightShow {
    from {
        transform: translatex(100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes fade {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.goup {
    animation: titleShow 1s;

}

.goup1 {
    animation: titleShow 1.5s;

}

.goup2 {
    animation: titleShow 2s;

}

.goup3 {
    animation: titleShow 2.5s;

}

.goup4 {
    animation: titleShow 3s;

}

.goup5 {
    animation: titleShow 3.5s;

}

.joinleft {
    animation: leftShow 1s;
}

.joinright {
    animation: rightShow 1s;
}

.joinleft1 {
    animation: leftShow 2s;
}

.joinright1 {
    animation: rightShow 2s;
}

.fade {
    animation: fade 2s;

}

.container {
    display: grid;
    overflow: auto;
    height: 100%;
    margin: 0 auto;
    overflow: visible;

    .service_box {
        height: 792px;
        background-image: url("../../assets/servicebg.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1422px;
            margin: 0 auto;
            display: flex;
        }

        .service_title {
            margin-top: 373px;
            font-size: 60px;
            font-weight: 300;
            letter-spacing: 0.1px;
            line-height: 86.88px;
            color: rgba(232, 232, 232, 1);

            p {
                margin: 0
            }

            p:last-child {
                height: 70px;
                font-size: 48px;
                font-weight: 400;
                color: rgba(232, 232, 232, 1);
            }
        }
    }

    .we_service {
        height: 912px;
        background-image: url("../../assets/4fa95b73e29c11cfbff6d0f278f0b1fe5231433d1d0160-ji05N0.png@2x.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-color: #fff;

        .main {
            width: 1422px;
            // height: 100%;
            margin: 0 auto;
            position: relative;
            // display: flex;
            // background-image: url("../../assets/water.png"); //背景图
            // background-repeat: no-repeat;
            // background-size: 812px 670px;
            // background-position: center center;

            .we_service_title {
                margin-left: 20px;
                margin-top: 85px;
                font-size: 36px;
                font-weight: 400;
                color: rgba(115, 115, 115, 1);

                p {
                    margin: 0;
                }

                p:last-child {
                    left: 268px;
                    margin-top: 20px;
                    font-size: 40px;
                    font-weight: 500;

                    color: rgba(100, 149, 237, 1);


                }
            }

            .we_service_center {
                height: 708px;
                background-image: url("../../assets/water.png"); //背景图
                background-repeat: no-repeat;
                background-size: 812px 670px;
                background-position: center center;
                position: relative;



                .algorithm {

                    font-size: 30px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 35.16px;
                    color: rgba(100, 149, 237, 1);

                    position: absolute;
                    left: 200px;
                    top: 78px;
                    width: 182px;
                    height: 182px;
                    border-radius: 182px;
                    text-align: center;
                    line-height: 182px;
                    // vertical-align: middle;
                    opacity: 1;
                    background: radial-gradient(50% 50%, rgba(100, 149, 237, 0) 0%, rgba(100, 149, 237, 0.06) 45.37%, rgba(100, 149, 237, 0.4) 100%);

                }

                .software {
                    position: absolute;

                    font-size: 30px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 35.16px;
                    color: rgba(100, 149, 237, 1);


                    left: 1050px;
                    top: 30px;
                    width: 179px;
                    height: 179px;
                    border-radius: 179px;
                    text-align: center;
                    line-height: 179px;
                    opacity: 1;
                    background: radial-gradient(50% 50%, rgba(100, 149, 237, 0) 0%, rgba(100, 149, 237, 0.06) 45.37%, rgba(100, 149, 237, 0.4) 100%);

                }

                .customize {
                    position: absolute;
                    font-size: 30px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 35.16px;
                    color: rgba(255, 255, 255, 1);
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -70%);
                    width: 240px;
                    height: 240px;
                    border-radius: 240px;
                    text-align: center;
                    line-height: 240px;
                    opacity: 1;
                    background: conic-gradient(from 90deg at 50% 50%, rgba(100, 149, 237, 0.63) 0%, rgba(100, 149, 237, 0.89) 24.96%, rgba(100, 149, 237, 0.44) 41.67%, rgba(100, 149, 237, 0.33) 61.55%, rgba(100, 149, 237, 1) 100%);
                }

                .visualization {
                    position: absolute;

                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 28.13px;
                    color: rgba(100, 149, 237, 1);


                    left: 145px;
                    top: 389px;
                    width: 143px;
                    height: 143px;
                    border-radius: 143px;
                    text-align: center;
                    line-height: 143px;
                    opacity: 1;
                    background: radial-gradient(50% 50%, rgba(100, 149, 237, 0) 0%, rgba(100, 149, 237, 0.06) 45.37%, rgba(100, 149, 237, 0.4) 100%);
                }

                .things {
                    position: absolute;

                    font-size: 24px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 28.13px;
                    color: rgba(100, 149, 237, 1);


                    left: 1114px;
                    top: 367px;
                    width: 155px;
                    height: 155px;
                    border-radius: 155px;
                    text-align: center;
                    line-height: 155px;
                    opacity: 1;
                    background: radial-gradient(50% 50%, rgba(100, 149, 237, 0) 0%, rgba(100, 149, 237, 0.06) 45.37%, rgba(100, 149, 237, 0.4) 100%);

                }
            }

            .jiantoubox {
                position: absolute;
                width: 47px;
                height: 42px;
                // top: 0
                bottom: 0;
                left: 50%;
                transform: translate(-50%, -0%);

                img {
                    width: 100%;
                    height: 100%;
                }

                // bottom: -10px;
            }
        }

    }

    .customization_service {
        height: 781px;
        background: rgba(29, 45, 86, 0.5);
        background-image: url("../../assets/分组 3@2x (1).png"); //背景图
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1646px;
            height: 100%;
            margin: 0 auto;
            // display: flex;
            background-image: url("../../assets/圆形 1@2x.png"); //背景图
            background-repeat: no-repeat;
            background-size: 755px 755px;
            background-position: center center;

            .title {
                padding-top: 85px;
                margin-left: 125px;

                p {
                    margin: 0;
                }

                p:first-child {
                    margin-bottom: 10px;
                    height: 53px;
                    font-size: 36px;
                    font-weight: 400;
                    line-height: 52.13px;
                    color: rgba(255, 255, 255, 1)
                }

                p:last-child {
                    height: 35px;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 34.75px;
                    color: rgba(230, 230, 230, 1);
                }
            }

            .content {
                position: relative;
                width: 100%;
                margin-top: 72px;
                height: 354px;
                // background-color: #fff;
                // display: flex;

                .border {
                    display: inline-block;
                    width: 354px;
                    height: 354px;
                    border-radius: 354px;
                    opacity: 1;
                    border: 0.6px solid rgba(181, 181, 181, 1);

                }

                .contentbox {
                    height: 100px;
                    width: 234px;
                    // line-height: 100%;
                    vertical-align: middle;
                    // background-color: red;
                    // text-align: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;

                    .content_title {
                        margin-bottom: 5px;
                        height: 35px;
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 34.75px;
                        color: rgba(255, 255, 255, 1);
                    }

                    .content_some {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 28px;
                        color: rgba(230, 230, 230, 1);
                    }
                }

                .analyse {
                    position: absolute;
                }

                .collect {
                    position: absolute;
                    left: 323px;
                }

                .excavate {
                    position: absolute;
                    left: 646px;


                }

                .mastermind {
                    position: absolute;
                    right: 323px;
                }

                .customize {
                    position: absolute;
                    right: 0;
                }

                .hoverthis {
                    border: 0;
                    background: rgba(100, 149, 237, 0.4);
                }
            }
        }

    }

    .after_sales {
        height: 950px;
        background-color: #fff;

        .main {
            width: 1486px;
            height: 100%;
            margin: 0 auto;

            .after_title {
                margin-top: 115px;
                width: 200px;
                height: 59px;
                font-size: 40px;
                font-weight: 400;
                line-height: 59px;
                color: rgba(115, 115, 115, 1);
            }

            .after_content {
                margin-top: 87px;
                height: 530px;

                // background-color: red;
                .line {
                    height: 287px;
                    width: 2px;
                    background-color: rgba(232, 232, 232, 1);
                }

                .top {
                    height: 287px;
                    border-bottom: solid 2px rgba(232, 232, 232, 1);
                    display: flex;

                    .left {
                        // border-right: solid 2px rgba(232, 232, 232, 1);
                    }
                }

                .bottom {
                    display: flex;
                    height: 287px;
                    // padding-top: 60px;

                    .left {
                        // border-right: solid 2px rgba(232, 232, 232, 1);
                    }

                    .content_box {
                        margin-top: 0px;
                    }
                }

                .content_box {
                    flex: 1;
                    margin-left: 45px;
                    padding-top: 60px;
                    display: flex;

                    .num {
                        margin-right: 30px;
                        width: 55px;
                        height: 64px;
                        font-size: 48px;
                        font-weight: 700;
                        line-height: 59px;
                        color: rgba(217, 217, 217, 1);
                    }

                    .some {
                        .some_title {
                            width: 96px;
                            height: 59px;
                            font-size: 24px;
                            font-weight: 400;
                            letter-spacing: 0px;
                            line-height: 59px;
                            color: rgba(100, 149, 237, 1);
                        }

                        .some_content {
                            margin-top: 23px;
                            width: 260px;
                            font-size: 16px;
                            font-weight: 400;
                            letter-spacing: 0.2px;
                            line-height: 23.17px;
                            color: rgba(115, 115, 115, 1);

                            p {
                                margin: 0;
                            }
                        }
                    }

                    .img {
                        margin-left: 54px;
                        width: 260.44px;
                        height: 198px;
                        opacity: 1;
                        border-radius: 6px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .footer {
        position: relative;
        height: 300px;
        color: #C4C4C4;
        background-color: #20262d;
    }
}
</style>
