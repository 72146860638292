import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "axios";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/global.scss";
import "./assets/iconfont/iconfont.css";
import * as echarts from "echarts";
import { VueJsonp } from "vue-jsonp";
import Moment from "moment";
import { Message } from "element-ui";

Vue.filter("formatDate", function (value, format = "YYYY-MM-DD HH:mm:ss") {
  return Moment(value).utc().format(format);
});

Vue.use(VueJsonp);
Vue.prototype.$echarts = echarts;

// / baseURL

// const baseURL = "http://192.168.101.21:3000/api";
const baseURL = "https://wwwapi.shuhan-juno.com/api";

Vue.use(ElementUI);

Vue.prototype.$http = axios;

axios.defaults.baseURL = `${baseURL}/`;

axios.interceptors.request.use((config) => {
  config.headers.Authorization =
    "Bearer" +
    " " +
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InNodWhhbiIsInBhc3N3b3JkIjoiTDB2ZXNodWhhbndlYnNpdGUuIn0.hpdiHLzZlgymoSkYaFw16udImp4RQ2vxESe3lJjpUsc";
  return config;
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
