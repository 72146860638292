import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/Home.vue";
// import Login from "../components/Login.vue";
import index from "../views/index.vue";
// import second from "../views/index copy.vue";
import detail from "../views/detail/detail.vue";
import Usercase from "../views/case/case.vue";
import Aboutus from "../views/aboutus/aboutus.vue";
import Service from "../views/service/service.vue";

// import Dthree from "../views/bigView/components/3dthree.vue";

// const originalPush = VueRouter.prototype.push;

// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

Vue.use(VueRouter);

const routes = [
  // { path: "", component: index },
  {
    path: "/",
    name: "Home",
    component: home,
    children: [
      {
        path: "/index",
        name: "index",
        component: index,
      },
      {
        path: "/detail",
        name: "detail",
        component: detail,
      },
      {
        path: "/case",
        name: "Usercase",
        component: Usercase,
      },
      {
        path: "/service",
        name: "Service",
        component: Service,
      },
      {
        path: "/aboutus",
        name: "Aboutus",
        component: Aboutus,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      selector: "#app",
    };
  },
});

export default router;
