<template>
  <el-container class="home-container">
    <!-- <el-header> -->
    <div class="header" :style="style">
      <div class="main">

        <div class="logo">
          <img class="loooogo" src="../assets/bai.png.png" />
        </div>
        <!-- <navbar class="navbar"></navbar> -->
        <div class="nav">
          <div class="text" v-for="i in navlist" @click="navclick(i)">
            {{ i.name }}
            <div class="line" v-if="clickindex == i.index"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- </el-header> -->
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>

    </el-container>
    <!-- <myfoooter @toTop="toTop"></myfoooter> -->
  </el-container>
</template>

<script>
import myfoooter from "../components/footer.vue";

// import "../assets/css/global.scss";
// import Update from "../views/setupdate copy.vue";
// import Navbar from "../components/navbar";
// import { ipcRenderer } from "electron";

export default {
  // components: { Navbar },
  components: {
    myfoooter,

  },

  data() {
    return {
      activePath: '/index',
      color: false,
      style: {
        backgroundColor: "",
      },
      navlist: [
        {
          index: 0,
          url: '/index',
          name: '首页'
        }, {
          index: 1,
          url: '/detail',
          name: '核心产品'
        }, {
          index: 2,
          url: '/case',
          name: '客户案例'
        }, {
          index: 3,
          url: '/service',
          name: '服务&售后'
        },
        {
          index: 4,
          url: '/aboutus',
          name: '关于我们'
        },
      ],
      clickindex: null
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // console.log(window.scroll);

    // window.addEventListener("scroll", this.handleScroll);
    if (this.$route.path !== '/') {
      console.log(this.$route.path);
      console.log(this.navlist);

      for (let a = 0; a < this.navlist.length; a++) {
        if (this.navlist[a].url === this.$route.path) {
          this.clickindex = this.navlist[a].index
          console.log(this.clickindex);
        }
      }
    } else {
      this.$router.push('/index');
      this.clickindex = 0
    }

    this.activePath = "/index";
    if (this.$route.path) {
      return
    } else {
      this.clickindex = 0
      // this.$router.push('/index');
    }
  },
  created() {

  },
  watch: {
    "$store.state.open"(newVal, oldVal) {
      // this.$nextTick(() => {
      console.log(newVal);
      // });
      this.clickindex = newVal
      console.log(this.clickindex);
    },
  },
  computed: {


  },

  methods: {
    navclick(i) {
      console.log(i);
      this.clickindex = i.index
      console.log(this.$route.path);
      if (this.$route.path == i.url) {
        return
      } else {
        this.$store.commit("changeopen", i.index);

        this.$router.push(i.url);
      }

    },
    handleScroll(e) {
      // console.log(123213123);
      // console.log(e);
      let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
      let scrollall = document.documentElement.clientHeight || document.body.offsetHeight

      // console.log(window.scrollY);
      // console.log(document.documentElement.scrollTop);
      // console.log(document.body.scrollTop);
      // let offsetTop = document.querySelector(".header").offsetTop;
      //设置背景颜色的透明读
      if (scrollTop > scrollall * 0.06) {
        this.style.backgroundColor = `rgba(64, 64, 64, 0.79)`;
        this.color = true;
      } else if (scrollTop == 0) {
        this.style.backgroundColor = "transparent";
        this.color = false;
      }
    },

  },
};
</script>
<style lang="scss" scoped>
.home-container {
  height: 100% !important;
}

.el-header {
  // height: 70px;
}

.header {
  .main {
    width: 1568px;
    margin: 0 auto;
    display: flex;

  }

  width: 100%;
  // margin-bottom: 20px;
  position: fixed;
  top: 0;
  display: flex;
  // color: #fff;
  font-size: 22px;
  align-items: center;
  justify-content: space-between;
  // justify-content: flex-end;
  z-index: 11;
  padding: 20px;
  animation: color 1s linear forwards;

  // background-color: red;
  @keyframes yellow-orange {
    from {
      color: yellow
    }

    to {
      color: deeppink
    }
  }

  .logo {
    // flex: 1;
    // margin-left: 137px;
    // margin-top: 40px;
    width: 121px;
    margin-right: 937px;

    img {
      width: 121px;
    }

  }

  .nav {
    // flex: 1;
    display: flex;
    // margin-left: auto;
    // background-color: red;
    font-size: 16px;
    height: 32px;
    line-height: 32px;
    padding-right: 150px;

    .text {
      // flex: 1;
      cursor: pointer;
      width: 110px;
      text-align: center;
      color: rgba(255, 255, 255, 1);
      // border-bottom: 2px solid rgb(31, 31, 31);

    }

    .line {
      content: '';
      width: 50%;
      height: 1px;
      display: block;
      margin: 0 auto;
      border-bottom: 3px solid rgba(255, 255, 255, 1)
    }

  }


  .touxiang {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-right: 30px;

    .yuan {
      position: absolute;
      z-index: 1;
      text-align: center;
    }

    .tx {
      position: absolute;
      z-index: 2;
    }
  }

  .admin {
    color: #4d4d4d;
    font-size: 14px;
    // margin-right: 30px;
  }

  .changetype {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 63px;
    height: 26px;
    opacity: 1;
    border-radius: 4px;
    background: rgba(225, 226, 226, 1);
    margin-left: 30px;
    margin-right: 40px;

    .sun {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 26px;
      height: 20px;
      opacity: 1;
      border-radius: 4px;
      background: rgba(250, 250, 252, 1);
      margin-left: 4px;
      box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }

    .moon {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 26px;
      height: 20px;
      opacity: 1;
      border-radius: 4px;
      // background: rgba(250, 250, 252, 1);
      margin-right: 4px;
      // box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    }
  }

  .loginout {
    margin-left: 20px !important;
    margin-right: 18px;
    margin-top: 16px;
    color: #00a8e8;
    // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .loginout1 {
    display: none;
  }
}

::v-deep .el-aside {
  // flex: 1;
  // background: rgba(28, 28, 28, 1);
  overflow: hidden !important;
  // margin-top: 14px !important;
  // margin-bottom: 20px !important;
  // border-radius: 12px !important;
  // height: 940px !important;
  // position: fixed !important;
  // overflow-x: hidden;
  // transition: 0.3s;
}

.el-main {

  overflow: inherit !important;
  // ::-webkit-scrollbar {
  //   width: 0px;
  // }

  padding: 0;
}

.el-aside:hover .shoqibutton {
  display: inline-block !important;
  cursor: pointer;
}

.el-menu {
  border-right: 0;
  // margin-top: 14px;
}

.asideIcon {
  padding-left: 20px;
}

.asideSpan {
  padding-left: 10px;
  font-size: 15px;
}

.subItemSpan {
  padding-left: 24px;
}

.btn-change-style {
  margin-top: 16px;
}

.styleChange {
  color: #1890ff;
}

.elAside {
  display: none !important;
}

.textbtn {
  margin-left: 20px !important;
  margin-right: 18px;
  margin-top: 16px;
  color: #1890ff;
  // box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.textbtn {
  color: #3cb371;
  font-weight: 600;
  // background-image: url("../assets/loading123.gif");
  // background-repeat: no-repeat; /*还有repeat-x,y等*/
  // background-size: 16%;
  // background-position: 95% 50%;
}

::v-deep .el-switch {
  .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
    right: 22px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
    left: 22px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
}

::v-deep .el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 44px !important;
}

// ::v-deep .el-menu-item {
//   background-color: red;
// }

.navbar {
  display: none
}

.header:hover .navbar {
  display: block;
}

// .navbar:hover .navbar {
//   display: block;
// }
</style>
