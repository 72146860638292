<template>
    <div class="numbox" style="display: flex;">
        <div v-for="(item, index) in numberList" :key="index" style="display: flex;">
            <span v-if="isNaN(item)">{{ item }}</span>
            <div class="number" v-else>
                <span class="number-item num" ref="numberItem" :data-number="item" :data-index="index">0123456879</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default: 0
        }
    },
    watch: {
        value(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.setNumberTransform();
                });
            }
        }
    },
    computed: {
        numberList() {
            return String(this.value).split("");
        }
    },
    data() {
        return {};
    },
    methods: {
        // 设置每一位数字的偏移
        setNumberTransform() {
            let numberItems = this.$refs.numberItem;
            let obj = {};
            Array.from(numberItems).forEach(c => {
                let key = c.dataset.index;
                let value = c.dataset.number;
                let init = 0;
                obj[key] = setInterval(() => {
                    if (init < value * 10) {
                        init += 1;
                        c.style.transform = `translateY(-${init}%)`;
                    } else {
                        clearInterval(obj[key]);
                        obj[key] = null;
                    }
                }, 10);
            });
        }
    },
    mounted() {
        this.setNumberTransform();
    }
};
</script>

<style scoped lang="less">
.numbox {
    // width: 35px;
    // height: 40px;
}

.number {
    // width: 35px;
    // height: 40px;
    overflow: hidden;

    .num {
        font-size: 36px;
        font-weight: 900;
        line-height: 47.48px;
        color: rgba(100, 149, 237, 1);
    }

    >span {
        writing-mode: vertical-rl;
        text-orientation: upright;
        transform: translateY(0%);
    }
}
</style>
