<template>
    <div class="footer">
        <div class="main">
            <div class="footer_top">
                <div class="top_left">
                    <div class="imgbox">

                        <img src="../assets/logo.png" alt="Image">
                        <div class="text">
                            SHUHAN TECHNOLOGIES
                        </div>
                    </div>
                </div>
                <div class="top_right">
                    <div class="icon" @click="toTop">
                        <el-tooltip content="返回顶部" placement="bottom" effect="light">
                            <img src="../assets/up.png" alt="">
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="footer_centre">
                <div class="centre_left">
                    <div class="icon_text">
                        <img src="../assets/icon1.png" alt="">
                        <div class="text">
                            AI运维决策控制系统
                        </div>
                    </div>
                    <div class="icon_text1">
                        <img src="../assets/icon3.png" alt="">
                        <div class="text">
                            数字可视化平台
                        </div>
                    </div>
                </div>
                <div class="centre_centre"
                    @click="downloadUrlFile('https://juno-statics.oss-cn-hangzhou.aliyuncs.com/statics/%E6%95%B0%E7%BF%B0%E5%AE%A3%E4%BC%A0%E5%86%8C.pdf', '数翰宣传册')">
                    <div class="icon_text">
                        <img src="../assets/icon2.png" alt="">
                        <div class="text">
                            数翰科技宣传册
                        </div>
                    </div>

                </div>
                <div class="centre_right">
                    <div class="right_title">
                        联系我们
                    </div>
                    <div class="callme">
                        <div class="wechat-qrcode show" v-if="wechatshow">
                            <button class="js-wechat-share-close-btn share-close" data-target=".share-open"
                                @click="wechatshow = false">×</button>
                            <h4>官方微信公众号</h4>
                            <div class="qrcode">
                                <img src="../assets/微信图片_20231228092535.jpg" class="lazyload loaded" alt="数翰官方微信公众号"
                                    data-was-processed="true">
                            </div>
                        </div>
                        <div class="icon" v-for="item in callmelist" @click="clickcallme(item)">
                            <div class="imgbox">
                                <el-tooltip :content="item.data" placement="bottom" effect="light" v-if="item.data">
                                    <img :src="item.url" alt="">
                                </el-tooltip>
                                <img :src="item.url" alt="" v-else>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer_end">
                <div class="text">

                    Copyright © 2023 数翰科技 浙ICP备2022032421号-1
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            callmelist: [
                {
                    index: 0,
                    url: require("../assets/wechat.png"),
                },
                {
                    index: 1,
                    url: require("../assets/email.png"),
                    data: 'SH@shuhan.com.cn  '
                },
                {
                    index: 2,
                    url: require("../assets/icon4.png"),
                    data: '0573-88891339'

                },
                {
                    index: 3,
                    url: require("../assets/icon5.png"),
                    data: '浙江省桐乡市环城北路518号智创园1号楼'

                }
            ],
            wechatshow: false,
            loginForm: {
                username: "shuhan",
                password: "L0veshuhanwebsite.",
            },
            scrollTop: 0
        };
    },
    created() { },
    mounted() {

        window.addEventListener("scroll", this.handleScroll, true);

    },

    methods: {
        clickcallme(item) {
            console.log(item);
            if (item.index == 0) {
                this.wechatshow = true
            }
        },
        // handleScroll(e) {
        //     console.log(e);
        //     let scrollTop = e.target.scrollTop;
        //     console.log(scrollTop);
        // },
        toTop(e) {
            document.documentElement.scrollTop = 0;
        },

        downloadUrlFile(url, fileName) {

            const url2 = url.replace(/\\/g, '/');
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url2, true);
            xhr.responseType = 'blob';
            xhr.onload = () => {
                if (xhr.status === 200) {
                    this.saveAs(xhr.response, fileName);
                }
            };
            xhr.send();
        },
        saveAs(data, name) {

            const blob = new Blob([data]);
            const fileName = '数翰科技宣传册.pdf'

            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }

        },
        async downtext() {

            const { data: res } = await this.$http.get("/file/download", {
                params: {
                    file_name: 'shuhan宣传册.pdf',
                },
                responseType: 'blob'
            });

            if (res.size > 0) {
                const content = res
                const blob = new Blob([content])
                const fileName = '数翰宣传册.pdf'
                if ('download' in document.createElement('a')) { // 非IE下载
                    const elink = document.createElement('a')
                    elink.download = fileName
                    elink.style.display = 'none'
                    elink.href = URL.createObjectURL(blob)
                    document.body.appendChild(elink)
                    elink.click()
                    URL.revokeObjectURL(elink.href) // 释放URL 对象
                    document.body.removeChild(elink)
                } else { // IE10+下载
                    navigator.msSaveBlob(blob, fileName)
                }
                /* this.$message.success("导出成功")*/
            }


        }
    },
};
</script>

<style lang="scss" scoped>
.footer {
    position: relative;
    height: 300px;
    color: #C4C4C4;
    background-color: #20262d;

    .main {
        width: 1498px;
        margin: 0 auto;
        position: relative;
        // background-color: rgb(203, 214, 106);
    }

    .footer_top {
        margin-top: 50px;
        height: 48px;
        vertical-align: middle;
        border-bottom: 1px solid #616161;
        display: flex;

        .top_left {
            margin-left: 52px;

            .imgbox {
                display: flex;
            }

            img {
                width: 27px;
            }

            .text {
                margin-left: 20px;
                font-size: 18px;
                line-height: 25px;
            }
        }

        .top_right {
            margin-left: auto;
            margin-right: 297px;
            cursor: pointer;
            // text-align: right;
        }
    }

    .footer_centre {
        // padding-top: 31px;
        height: 123px;
        border-bottom: 1px solid #616161;

        display: flex;

        .centre_left {
            margin-top: 31px;
            margin-left: 52px;

            img {
                width: 18px;
                height: 18px;
            }

            .text {
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                margin-left: 20px;
            }

            .icon_text {
                // margin-top: 14px;
                display: flex;
            }

            .icon_text1 {
                margin-top: 14px;
                display: flex;
            }
        }

        .centre_centre {
            margin-left: 260px;
            cursor: pointer;

            img {
                width: 18px;
                height: 18px;
            }

            .text {
                font-size: 16px;
                font-weight: 400;
                line-height: 22.4px;
                margin-left: 20px;
            }

            .icon_text {
                margin-top: 54px;
                display: flex;
            }
        }

        .centre_right {
            margin-left: auto;
            // margin-right: 44px;
            margin-top: 21px;

            .right_title {

                font-size: 16px;
                font-weight: 400;


            }

            .callme {

                display: flex;
                margin-top: 17px;

                .icon {
                    height: 41px;
                    width: 41px;
                    // border-radius: 41px;
                    // border: 1px solid rgba(122, 122, 122, 1);
                    margin-right: 35px;
                    text-align: center;
                    vertical-align: middle;
                    line-height: 48px;
                    cursor: pointer;

                    img {
                        width: 100%;
                    }
                }
            }

            .wechat-qrcode {

                box-sizing: border-box;
                margin: 0;
                padding: 0;
                outline: none;
                display: block !important;
                position: absolute;
                z-index: 999;
                right: 190px;
                width: 200px;
                color: #666;
                font-size: 12px;
                text-align: center;
                transition: all 200ms;
                height: 176px;
                top: -76px;
                border: 1px solid #eee;
                background-color: #fff;
                box-shadow: 0 2px 10px #aaa;

                .share-close {
                    background: transparent;
                    border: 0;
                    font-size: 25px;
                    position: absolute;
                    top: -6px;
                    right: 2px;
                    color: #000 !important;
                }

                h4 {
                    height: 26px;
                    line-height: 26px;
                    font-size: 12px;
                    background-color: #f3f3f3;
                    margin: 0;
                    padding: 0;
                    color: #777;
                }

                .qrcode {
                    width: 138px;
                    margin: 5px auto;
                }

                img {
                    width: 100%;
                    vertical-align: middle;
                }
            }

            .wechat-qrcode:after {
                border-width: 8px 6px 6px 6px;
                top: auto;
                border-color: #f3f3f3 transparent transparent transparent;
            }

            .wechat-qrcode:after {
                content: '';
                position: absolute;
                left: 50%;
                margin-left: -6px;
                // top: -13px;
                width: 0;
                height: 0;
                // border-width: 6px 6px 8px 6px;
                border-style: solid;
                // border-color: transparent transparent #f3f3f3 transparent;
            }
        }
    }

    .footer_end {
        .text {
            margin-left: 52px;
            margin-top: 21px;
            font-size: 14px;
            font-weight: 400;

            line-height: 18.47px;
            color: #C4C4C4;
        }



    }

}
</style>