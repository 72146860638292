<template>
    <div id="mapcontainer"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
    securityJsCode: 'dbb0bba0d4c0fe08eced203543c8ea0e'
}
export default {
    data() {
        return {
            map: null
        }
    },
    methods: {
        initMap() {
            AMapLoader.load({
                key: '	e0ab7d1caec598a2f38b86db828c6f21', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [''] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then(AMap => {
                    var marker = new AMap.Marker({
                        position: new AMap.LngLat(120.567, 30.658),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                        title: '北京'
                    });
                    this.map = new AMap.Map('mapcontainer', {
                        //设置地图容器id
                        viewMode: '3D', //是否为3D地图模式
                        zoom: 11, //初始化地图级别
                        center: [120.567, 30.658] //初始化地图中心点位置
                    })
                    this.map.add(marker);
                })
                .catch(e => {
                    console.log(e)
                })
        }
    },
    mounted() {
        //DOM初始化完成进行地图初始化
        this.initMap()
    }
}
</script>

<style lang="scss" scoped>
#mapcontainer {
    padding: 0px;
    margin: 0px;
    width: 652px;
    height: 525px;
    border-radius: 10px;
}
</style>

