import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    open: "",
  },
  getters: {},
  mutations: {
    changeopen(state, status) {
      console.log(status);
      state.open = status;
    },
  },
  actions: {},
  modules: {},
});
